import backgroundColors from "core/src/constants/backgroundColors";

export default function setTitleAndDescriptions(titleText, descriptionText) {
  const title = document.createElement('div');
  const description = document.createElement('div');
  const title_describ = document.createElement("div");
  title_describ.id = "title_describ";
  title_describ.appendChild(title);
  title_describ.appendChild(description);
  const body = document.body;
  body.removeChild(body.children[0]);
  body.removeChild(body.children[0]);
   const element = document.getElementById("demo-toolbar");
   element.classList.add("flex_toolbar");

  const referenceElement = document.body.children[0];
  const width = window.innerWidth;
  const height = window.innerHeight;
 const fpsSlider = document.getElementById("fpsSlider");
const sliderLeft = window.innerWidth < 700 ? width * 0.000009766 : width * 0.006;
//console.log(sliderLeft);
  title.id = "title";
  description.id = "description";
  const contentStyles = {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "center"

  };


  const sliderStyles = {
    transform: 'rotate(90deg)',
  position: 'absolute',
  left: `${width < 400 ? -9.5 : -14}%`,
  transformOrigin: 'center',
  width: '100px', // Adjust the width as needed
  height: '20px',
 };
 const playClipstyles = {
  position: 'relative',
  left: `${width * 0.007}`,

 }

  const toolbarStyles = {
    width: `${width * 0.96}px`,
    height: `${height * 0.16}px`,
    marginLeft: `${width * 0.002}px`,
    marginRight: `${width * 0.002}px`, // 80% of the screen width
    background: '#333',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '20px'
  };

  // Assign the styles
//const fpsilder = document.querySelector("#fpsSlider-label");
//fpsilder.style.display = "none";

   Object.assign(title_describ.style, contentStyles);
   Object.assign(element.style, toolbarStyles )

   //document.body.appendChild(title_describ);
  document.body.insertBefore(title_describ, referenceElement);
 //document.getElementById("content").appendChild(title_describ);
  title.innerText = titleText;
  description.innerText = descriptionText;
  window.onload = function() {
    const toolbar = document.getElementById('demo-toolbar');
    let isSliding = false;
const slider = document.getElementById("fpsSlider");
document.getElementById("fpsSlider").addEventListener('touchstart', () => {
  isSliding = true;


});

document.body.addEventListener('touchmove', (e) => {

  if (isSliding && e.target === document.getElementById("fpsSlider")) {
    e.preventDefault();
  }
});

document.getElementById("fpsSlider").addEventListener('touchend', () => {
  isSliding = false;
  if (!document.getElementsByClassName("playstop").length) {
    //  csToolsUtilities.cine.playClip(activeElement, { framesPerSecond });
      document.getElementById('playclip').classList.contains('playstop') || document.getElementById('playclip').classList.add('playstop');
      document.getElementById('playclip').textContent = 'Stop Slices';

    }
});

// Get all children of the toolbar except the first element
const arraychildren = Array.from(toolbar.children);
const childWidth = width * 0.01 / 4;
// Create a new parent element
const subtoolbar = document.createElement('div');
subtoolbar.id = "subtoolbar";

const pageContentHoverStyles = {
  transform: 'rotateY(0deg) rotateX(0deg) translateZ(60px)',
  boxShadow: '0 15px 30px rgba(0, 0, 0, 0.3)'
};

const chevronRightStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  right: '10px'
};

const chevronLeftStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  left: '10px'
};

// Usage:

const subtoolbarstyles = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'absolute',


};

// Append each child to the new parent element
arraychildren.forEach(child => {
  subtoolbar.appendChild(child)

});


// Append the new parent element to the toolbar
toolbar.appendChild(subtoolbar);
const subtoolbarselected = document.querySelector('#subtoolbar');
Array.from(subtoolbarselected.children).forEach(child => {
  (child as HTMLElement).style.width = childWidth.toString();
});
const select = document.createElement('select');
select.id = "selectelement";
select.innerHTML = `
  <option value="1x">1x</option>
  <option value="3x">3x</option>
`;
const content = document.getElementById('content');

// Add the select element to the content element
subtoolbarselected.appendChild(select);
Object.assign(content.style, contentStyles);
// Define the styles for each view mode
const viewportstyles = {
  '1x': {
    viewportGrid: { width: `${width * 0.8}px`, marginLeft: `${width * 0.1}px`, marginRight: `${width * 0.1}px`,
    borderRadius: '12px',
    border: '1px solid #ccc',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    transform: 'translateZ(50px)',
    background: 'white',
    transition: 'transform 0.5s ease, box-shadow 0.5s ease',
    transformOrigin: 'bottom left',
    backfaceVisibility: 'hidden',
    overflow: 'hidden',
    transformStyle: 'preserve-3d'},

   // viewport: { display: 'block' },
    chevron:  {
      position: 'absolute',
      top: `${height * 0.5}`,
      transform: 'translateY(-50%)',
      background: 'none',

      fontSize: '24px',
      cursor: 'pointer',
    },
  },
  '3x': {
   viewportGrid: { width: `${width}`, marginLeft: '0px', marginRight: '0px', display: 'flex', flexDirection: 'row'},
   // viewport: { display: 'flex' },
    chevron: { display: 'none' },
  },
};

const viewportGrid = document.getElementById("viewportGrid");
const viewportGridchildren = viewportGrid.children
//Object.assign(viewportGrid.style, viewportstyles["1x"].viewportGrid);


// Add an event listener to the select element
select.addEventListener('change', (e) => {
  const viewMode = (e.target as HTMLSelectElement).value;
  const content = document.getElementById('content');
  const viewportGrid = document.getElementById("viewportGrid");
  const viewports = content.querySelectorAll('#cornerstone-element');
  const chevrons = content.querySelectorAll('.chevron');

  // Update the styles based on the selected view mode

  Object.assign(viewportGrid.style, viewportstyles[viewMode].viewportGrid);
  //viewports.forEach((viewport) => Object.assign(viewport.style, viewportstyles[viewMode].viewport));
  chevrons.forEach((chevron) => Object.assign((chevron as HTMLElement).style, viewportstyles[viewMode].chevron));

  // Show/hide viewports based on the selected view mode
  if (viewMode === '1x') {
    viewports.forEach((viewport, index) => {
      (viewport as HTMLElement).style.display = index === 0 ? 'block' : 'none';
    });
  } else {
    viewports.forEach((viewport) => {
      (viewport as HTMLElement).style.display = 'flex';
    });
  }
});

    // Select the element here
    const fpsSlider = document.getElementById("fpsSlider");
    const playClip = document.getElementById("playclip");
    Object.assign(fpsSlider.style, sliderStyles);
    Object.assign(playClip.style, playClipstyles);
    Object.assign(subtoolbar.style, subtoolbarstyles );
  };
}
