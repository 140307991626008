import {
  RenderingEngine,
  init as csInit,
  Types,
  Enums,
  metaData,
  getEnabledElement,
  utilities as csUtils,
  volumeLoader,
  getRenderingEngine,
  setVolumesForViewports,
} from '@cornerstonejs/core';
import {
  initDemo,
  createImageIdsAndCacheMetaData,
  addButtonToToolbar,
  setCtTransferFunctionForVolumeActor,
  addSliderToToolbar,
  addDropdownToToolbar,
  addManipulationBindings,
  getLocalUrl,
  addToggleButtonToToolbar,

} from '../../../../utils/demo/helpers';
import setTitleAndDescriptions from './customeTitle';
import CustomOrientationMarkerTool from './customorientation';
import {
  cornerstoneNiftiImageVolumeLoader,
  Enums as NiftiEnums,
} from '@cornerstonejs/nifti-volume-loader';
import { vec3 } from 'gl-matrix';
import * as cornerstoneTools from '@cornerstonejs/tools';

import { utilities } from '@cornerstonejs/tools';
import { event } from '@kitware/vtk.js/macros';
import { ViewTypes } from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants';
const configLable = false;
const {
  LengthTool,
  HeightTool,
  ToolGroupManager,
  StackScrollMouseWheelTool,
  ZoomTool,
  Enums: csToolsEnums,
  init: csTools3dInit,
  ProbeTool,
  RectangleROITool,
  EllipticalROITool,
  CircleROITool,
  WindowLevelTool,
  PanTool,
  BidirectionalTool,
  AngleTool,
  CobbAngleTool,
  ArrowAnnotateTool,
  utilities: csToolsUtilities,
   CrosshairsTool,
  synchronizers,


} = cornerstoneTools;


// This is for debugging purposes
console.warn(
  'Click on index.ts to open source code for this example --------->'
);



const { ViewportType, Events } = Enums;
const { MouseBindings, ToolModes, KeyboardBindings } = csToolsEnums;

const { createSlabThicknessSynchronizer } = synchronizers;
// ======== Set up page ======== //
setTitleAndDescriptions(
  'MedicxViewer',
  ''
);
const {innerWidth, innerHeight} = window;
const width = `${innerWidth * 0.7}px`;
const height = `${innerHeight * 0.6}px`;
const size = '500px';
const inactiveBorder = 'solid 5px rgba(0, 0, 0, 0)';
const activeBorder = 'solid 1px rgba(255, 0, 0, 1)';
const content = document.getElementById('content');
const viewportGrid = document.createElement('div');
const pageanimationData = document.createElement("div");
pageanimationData.id = "pageanimate";
pageanimationData.setAttribute("rotation", '0');
pageanimationData.setAttribute("currentPage", '0');


viewportGrid.id = "viewportGrid";
const dataElement = document.createElement("div");
dataElement.id = "datas-details";
const numViewports = 3;
const elements = [];
const defaultFramesPerSecond = 24;
let framesPerSecond = defaultFramesPerSecond;
let activeElement = null;
let viewport, rotation, flipHorizontal, flipVertical;

viewportGrid.style.display = 'flex';
viewportGrid.style.flexDirection = 'row';
viewportGrid.style.marginTop = `${innerHeight * 0.05}px`;
let elementShow = false;

for (let i = 0; i < numViewports; i++) {
  const element = document.createElement('div');

  element.id = 'cornerstone-element';
  element.style.width = width;
  element.style.height = height;
  element.style.padding = '1px';
  element.style.marginTop =  '5px';
  element.style.border = inactiveBorder;
  element.style.borderRadius = '12px';
  element.style.marginLeft = `${innerWidth * 0.025}px`;
  elements.push(element);
 if (i === 0) {
  viewportGrid.appendChild(element);
 }
  element.addEventListener('click', function () {
    const clickViewport = getEnabledElement(element)?.viewport;
    viewport = clickViewport;
    setActiveElement(this);
  });


  // Disable right click context menu so we can have right click tools
  element.oncontextmenu = (e) => e.preventDefault();
}
//const displayChild = viewportGrid[currentPage];
//displayChild.style.display = "block";

const { getOrientationStringLPS, invertOrientationStringLPS } = utilities.orientation;
content.appendChild(viewportGrid);
content.appendChild(pageanimationData);
let animationValue = document.getElementById("pageanimate");
  let rotationValue = animationValue.getAttribute("rotation");
  let currentIndex = 0;
  let viewGridcontent = document.getElementById("viewportGrid");
  let viewPortchildren = viewGridcontent.children;

  //(viewPortchildren[2] as HTMLElement).style.display = "block";
  let currentPage = animationValue.getAttribute("currentPage");
content.appendChild(pageanimationData);
const animateCurl = (direction) => {
  //const pageContent = pageContentRef.current;

  let start = null;


  const duration = 500; // Duration of the curl effect in milliseconds

  const step = (timestamp) => {
    if (!start) start = timestamp;
    const progress = timestamp - start;
    //const angle = Math.min(progress / duration, 1) * Math.PI / 6; // Curl angle up to 30 degrees

    if (direction === 'next') {
      //setRotation(rotation - 180);
      document.getElementById("pageanimate").setAttribute("rotation", '-180');

      rotationValue = document.getElementById("pageanimate").getAttribute("rotation");
     // currentPage = currentPage + 1;

    } else if (direction === 'prev') {
      //setRotation(rotation + 180);
      document.getElementById("pageanimate").setAttribute("rotation", '180');

      rotationValue = document.getElementById("pageanimate").getAttribute("rotation");
    }
  if (progress < duration) {
    requestAnimationFrame(step);
  }else {
    if (direction === 'next') {
      //setCurrentPage((prevPage) => prevPage + 1);
      currentIndex = (currentIndex + 1) % elements.length;
        }else if (direction === 'prev') {
       // setCurrentPage((prevPage) => prevPage + 1);
       currentIndex = (currentIndex - 1 + elements.length) % elements.length;

      }
    }
    rotationValue = '0';
  };
  requestAnimationFrame(step);

};




const handleNextPage = () => {
  if (Number(currentPage) < elements.length - 1) {
    animateCurl('next');
  }
  let recentElement = elements[currentPage];
  recentElement.style.display = "block";
  setActiveElement(elements[currentPage]);

};

const handlePrevPage = () => {
  if (Number(currentPage) > 0) {
    animateCurl('prev');
  }
};
/**
const viewportsDescription = document.createElement('p');
viewportsDescription.innerHTML = `
  <b>Viewports:</b>
  <ol>
    <li>Stack / Axial (as the slices are loading one by one, the first couple of loops will be slower than the rest)</li>
    <li>Volume / Coronal</li>
    <li>Volume / Sagittal</li>
    <li>Volume / Oblique</li>
  </ol>
`;

content.append(viewportsDescription);

const instructions = document.createElement('p');
instructions.innerText = `
  - Click on Play Clip to start the CINE tool
  - Click on Stop Clip to stop the CINE tool
  - Drag the frame slider to change the frames per second rate
`;

content.append(instructions);
*/
// ============================= //

const toolGroupId = 'STACK_TOOL_GROUP_ID';
const toolGroupId2 =  'MY_TOOLGROUP_ID';
const viewportIds = [
  'CT_NIFTI_AXIAL',
  'CT_NIFTI_SAGITTAL',
  'CT_NIFTI_CORONAL'
];
const toolModes = [
  ToolModes.Active,
  ToolModes.Passive,
  ToolModes.Enabled,
  ToolModes.Disabled,
];
const niftiURL =
'http://localhost:3000/images/002-t1_mpr_sag_standard.nii';

const volumeId = 'nifti:' + niftiURL;
const synchronizerId = 'SLAB_THICKNESS_SYNCHRONIZER_ID';
const renderingEngineId = 'myRenderingEngine';
const toolsNames = [
  WindowLevelTool.toolName,
  PanTool.toolName,
  LengthTool.toolName,
  HeightTool.toolName,
  ProbeTool.toolName,
  RectangleROITool.toolName,
  EllipticalROITool.toolName,
  CircleROITool.toolName,
  BidirectionalTool.toolName,
  AngleTool.toolName,
  CobbAngleTool.toolName,
  ArrowAnnotateTool.toolName,
  CrosshairsTool.toolName,
  ZoomTool.toolName,



];
let selectedToolName = toolsNames[0];
let selectedToolMode = toolModes[0];

addSliderToToolbar({
  id: 'fpsSlider',
  isLabel: false,
  title: ` Frames per second: ${framesPerSecond}`,
  range: [1, 100],
  defaultValue: framesPerSecond,
  onSelectedValueChange: (value) => {

    csToolsUtilities.cine.stopClip(activeElement);
    framesPerSecond = Number(value);
    csToolsUtilities.cine.playClip(activeElement, { framesPerSecond });
  },
  updateLabelOnChange: (value, label) => {
    label.innerText = ` Frames per second: ${value}`;
  },
});

let isPlaying = false;

addButtonToToolbar({
  id: "playclip",
  title: 'Scroll Slices',
  onClick: () => {

    if (!document.getElementsByClassName("playstop").length) {
      csToolsUtilities.cine.playClip(activeElement, { framesPerSecond });
      document.getElementById('playclip').classList.contains('playstop') || document.getElementById('playclip').classList.add('playstop');
      document.getElementById('playclip').textContent = 'Pause';

    } else {
      csToolsUtilities.cine.stopClip(activeElement);
      document.getElementById('playclip').classList.remove("playstop");
      document.getElementById('playclip').textContent = 'Scroll Slices';


    }


  },
});


//addButtonToToolbar({
 // id: 'stopclip',
  //title: 'Stop Clip',
  //onClick: () => {
   // csToolsUtilities.cine.stopClip(activeElement);
  //},
//});


const isMobile = window.matchMedia('(any-pointer:coarse)').matches;

addDropdownToToolbar({
  options: { values: toolsNames, defaultValue: selectedToolName },
  onSelectedValueChange: (newSelectedToolNameAsStringOrNumber) => {
    const newSelectedToolName = String(newSelectedToolNameAsStringOrNumber);
    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

    // Disable all tools first


    // Set the new tool active
    if (newSelectedToolName === CrosshairsTool.toolName) {


      toolGroup.addTool(CrosshairsTool.toolName, {
        getReferenceLineColor,
        getReferenceLineControllable,
        getReferenceLineDraggableRotatable,
        getReferenceLineSlabThicknessControlsOn,
        mobile: {
          enabled: isMobile,
          opacity: 0.8,
          handleRadius: 9,
        },
      });
      (<HTMLInputElement>document.querySelector('#intensitydropdown')).style.display = "inline-block";
      (<HTMLInputElement>document.querySelector('#syncSlabThickness')).style.display = "inline-block";
    } else {

      (<HTMLInputElement>document.querySelector('#intensitydropdown')).style.display = "none";
      (<HTMLInputElement>document.querySelector('#syncSlabThickness')).style.display = "none";
    }

    toolGroup[`setTool${ToolModes.Active}`](newSelectedToolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Primary, // Left Click (only applies if active)
        },
      ],
    });


    // Set the old tool passive
    toolGroup[`setTool${ToolModes.Disabled}`](selectedToolName); // setToolPassive

    selectedToolName = <string>newSelectedToolName;
  },
});
const rotations = ['0', '45', '60', '90', '135', '180', '270'];
addToggleButtonToToolbar({
  id: 'flipv',
  title: 'Flip V',
  onClick: () => {
    // Get the rendering engine
    viewport = getEnabledElement(activeElement)?.viewport;

    const { flipVertical } = viewport.getCamera();
    viewport.setCamera({ flipVertical: !flipVertical });
    roatateClicked(activeElement);
    viewport.render();
  },
});
addToggleButtonToToolbar({
  id: 'fliph',
  title: 'Flip H',
  onClick: () => {
    // Get the rendering engine
    viewport = getEnabledElement(activeElement)?.viewport;

    const { flipHorizontal } = viewport.getCamera();
    viewport.setCamera({ flipHorizontal: !flipHorizontal });
    roatateClicked(activeElement);
    viewport.render();
  },
});
addDropdownToToolbar({
  id: 'rotation',
  options: {
    values: rotations,
    defaultValue: rotations[0],
  },
  onSelectedValueChange: (value) => {
    viewport.setProperties({ rotation: value });
    roatateClicked(activeElement);
    viewport.render();
  },
});

const viewportColors = {
  [viewportIds[0]]: 'rgb(200, 0, 0)',
  [viewportIds[1]]: 'rgb(200, 200, 0)',
  [viewportIds[2]]: 'rgb(0, 200, 0)',
};

let synchronizer;

const viewportReferenceLineControllable = [
  viewportIds[0],
  viewportIds[1],
  viewportIds[2],
];

const viewportReferenceLineDraggableRotatable = [
  viewportIds[0],
  viewportIds[1],
  viewportIds[2],
];

const viewportReferenceLineSlabThicknessControlsOn = [
  viewportIds[0],
  viewportIds[1],
  viewportIds[2],
];
function getReferenceLineColor(viewportId) {
  return viewportColors[viewportId];
}

function getReferenceLineControllable(viewportId) {
  const index = viewportReferenceLineControllable.indexOf(viewportId);
  return index !== -1;
}

function getReferenceLineDraggableRotatable(viewportId) {
  const index = viewportReferenceLineDraggableRotatable.indexOf(viewportId);
  return index !== -1;
}

function getReferenceLineSlabThicknessControlsOn(viewportId) {
  const index =
    viewportReferenceLineSlabThicknessControlsOn.indexOf(viewportId);
  return index !== -1;
}
function assignStyles() {
  const styles = `
    .ViewportOrientationMarkers {
      position: relative; /* Add this */
      --marker-width: 100px;
      --marker-height: 100px;
      --scrollbar-width: 20px;
      pointer-events: none;
      font-size: 15px;
      line-height: 18px;
    }
    .ViewportOrientationMarkers .orientation-marker {
      position: absolute;
      transform: translate(-50%, -50%); /* Center the labels */
    }
    .ViewportOrientationMarkers .top-mid {
      top: 0;
  left: 50%;
  transform: translate(-50%, 0);
    }
    .ViewportOrientationMarkers .left-mid {
      top: 50%; /* Adjusted */
      left: 0;
    }
    .ViewportOrientationMarkers .right-mid {
      top: 50%; /* Adjusted */
      right: 0;
    }
    .ViewportOrientationMarkers .bottom-mid {
      bottom: 0;
  left: 50%;
  transform: translate(-50%, 0); /* Adjusted */
    }
  `;
  const styleElement = document.createElement('style');
  styleElement.type = 'text/css';
  styleElement.innerHTML = styles;
  document.head.appendChild(styleElement);
}
function _getOrientationMarkers(rowCosines, columnCosines, rotation, flipVertical, flipHorizontal) {
  const rowString = getOrientationStringLPS(rowCosines);
  const columnString = getOrientationStringLPS(columnCosines);
  const oppositeRowString = invertOrientationStringLPS(rowString);
  const oppositeColumnString = invertOrientationStringLPS(columnString);

  const markers = {
    top: oppositeColumnString,
    left: oppositeRowString,
    right: rowString,
    bottom: columnString,
  };

  // If any vertical or horizontal flips are applied, change the orientation strings ahead of
  // the rotation applications
  if (flipVertical) {
    markers.top = invertOrientationStringLPS(markers.top);
    markers.bottom = invertOrientationStringLPS(markers.bottom);
  }

  if (flipHorizontal) {
    markers.left = invertOrientationStringLPS(markers.left);
    markers.right = invertOrientationStringLPS(markers.right);
  }

  // Swap the labels accordingly if the viewport has been rotated
  // This could be done in a more complex way for intermediate rotation values (e.g. 45 degrees)
  if (rotation === 90 || rotation === -270) {
    return {
      top: markers.left,
      left: invertOrientationStringLPS(markers.top),
      right: invertOrientationStringLPS(markers.bottom),
      bottom: markers.right, // left
    };
  } else if (rotation === -90 || rotation === 270) {
    return {
      top: invertOrientationStringLPS(markers.left),
      left: markers.top,
      bottom: markers.left,
      right: markers.bottom,
    };
  } else if (rotation === 180 || rotation === -180) {
    return {
      top: invertOrientationStringLPS(markers.top),
      left: invertOrientationStringLPS(markers.left),
      bottom: invertOrientationStringLPS(markers.bottom),
      right: invertOrientationStringLPS(markers.right),
    };
  }

  return markers;
}
const cameraModifiedListener = (evt: Types.EventTypes.CameraModifiedEvent) => {


  const { rotation, previousCamera, camera } = evt.detail;

  if (rotation !== undefined) {
    dataElement.setAttribute("rotation", rotation.toString());
  }

  if (
    camera.flipHorizontal !== undefined &&
    previousCamera.flipHorizontal !== camera.flipHorizontal
  ) {
    dataElement.setAttribute("flipHorizontal", camera.flipHorizontal.toString());
   // setFlipHorizontal(camera.flipHorizontal);
  }

  if (
    camera.flipVertical !== undefined &&
    previousCamera.flipVertical !== camera.flipVertical
  ) {
   // setFlipVertical(camera.flipVertical);
   dataElement.setAttribute("flipVertical", camera.flipVertical.toString());

  }
content.appendChild(dataElement);
};
const viewportInputArray = [
  {
    viewportId: viewportIds[0],
    type: ViewportType.ORTHOGRAPHIC,
    element: elements[0],
    defaultOptions: {
      orientation: Enums.OrientationAxis.AXIAL,
      background: <Types.Point3>[0.2, 0, 0.2],
    },
  },
  {
    viewportId: viewportIds[1],
    type: ViewportType.ORTHOGRAPHIC,
    element: elements[1],
    defaultOptions: {
      orientation: Enums.OrientationAxis.CORONAL,
      background: <Types.Point3>[0.2, 0, 0.2],
    },
  },
  {
    viewportId: viewportIds[2],
    type: ViewportType.ORTHOGRAPHIC,
    element: elements[2],
    defaultOptions: {
      orientation: Enums.OrientationAxis.SAGITTAL,
      background: <Types.Point3>[0.2, 0, 0.2],
    },
  },

];

const blendModeOptions = {
  MIP: 'Maximum Intensity Projection',
  MINIP: 'Minimum Intensity Projection',
  AIP: 'Average Intensity Projection',
};

addDropdownToToolbar({
  id: 'intensitydropdown',
  options: {
    values: [
      'Maximum Intensity Projection',
      'Minimum Intensity Projection',
      'Average Intensity Projection',
    ],
    defaultValue: 'Maximum Intensity Projection',
  },
  onSelectedValueChange: (selectedValue) => {
    let blendModeToUse;
    switch (selectedValue) {
      case blendModeOptions.MIP:
        blendModeToUse = Enums.BlendModes.MAXIMUM_INTENSITY_BLEND;
        break;
      case blendModeOptions.MINIP:
        blendModeToUse = Enums.BlendModes.MINIMUM_INTENSITY_BLEND;
        break;
      case blendModeOptions.AIP:
        blendModeToUse = Enums.BlendModes.AVERAGE_INTENSITY_BLEND;
        break;
      default:
        throw new Error('undefined orientation option');
    }

    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

    const crosshairsInstance = toolGroup.getToolInstance(
      CrosshairsTool.toolName
    );
    const oldConfiguration = crosshairsInstance.configuration;

    crosshairsInstance.configuration = {
      ...oldConfiguration,
      slabThicknessBlendMode: blendModeToUse,
    };

    // Update the blendMode for actors to instantly reflect the change
    toolGroup.viewportsInfo.forEach(({ viewportId, renderingEngineId }) => {
      const renderingEngine = getRenderingEngine(renderingEngineId);
      const viewport = renderingEngine.getViewport(
        viewportId
      ) as Types.IVolumeViewport;

     // viewport.setBlendMode(blendModeToUse);
      viewport.render();
    });
  },
});
//toggle.setElementShow(false);

addToggleButtonToToolbar({
  id: 'syncSlabThickness',
  title: 'Sync Slab Thickness',
  defaultToggle: false,
  onClick: (toggle) => {
    synchronizer.setEnabled(toggle);
  },
});
function setUpSynchronizers() {
  synchronizer = createSlabThicknessSynchronizer(synchronizerId);

  // Add viewports to VOI synchronizers
  viewportIds.forEach((viewportId) => {
    synchronizer.add({
      renderingEngineId,
      viewportId,
    });
  });
  // Normally this would be left on, but here we are starting the demo in the
  // default state, which is to not have a synchronizer enabled.
  synchronizer.setEnabled(false);
}
(<HTMLInputElement>document.querySelector('#intensitydropdown')).style.display = "none";
(<HTMLInputElement>document.querySelector('#syncSlabThickness')).style.display = "none";
/**
 * Updated active element's style and stores it
 * @param element - Cornerstone element
 */
function setActiveElement(element) {
  if (activeElement) {
    activeElement.style.border = inactiveBorder;
  }

  activeElement = element;
  activeElement.style.border = activeBorder;

  const { framesPerSecond: fps = defaultFramesPerSecond } =
    csToolsUtilities.cine.getToolState(activeElement) ?? {};

  (<HTMLInputElement>document.querySelector('#fpsSlider')).value = fps;

  // (<HTMLElement>(
   // document.querySelector('#fpsSlider-label')
  //)).innerText = ` Frames per second: ${fps}`;

}

function testingViewport(elements, orientationMarkers = ['top', 'left', 'right', 'bottom']) {
  elements.forEach((element, index) => {
    const renderingEngine = getRenderingEngine(renderingEngineId);
    const viewport = <Types.IVolumeViewport>(renderingEngine.getViewport(viewportIds[index]));

   // orientationMarkerTool.addOrientationMarker(viewport);
  });
}
function roatateClicked(element, orientationMarkers = ['top', 'left','right','bottom'] ) {
  let rowCosines, columnCosines;
  const customorientation = new CustomOrientationMarkerTool();
  viewport = getEnabledElement(element)?.viewport;
  element.addEventListener(Enums.Events.CAMERA_MODIFIED, cameraModifiedListener);
  const { viewUp, viewPlaneNormal } = viewport.getCamera();

  rotation = parseFloat(dataElement.getAttribute('rotation'));
  flipVertical = parseFloat(dataElement.getAttribute("flipVertical"));
  flipHorizontal = parseFloat(dataElement.getAttribute("flipHorizontal"));

      const viewRight = vec3.create();
      vec3.cross(viewRight, viewUp, viewPlaneNormal);

      columnCosines = [-viewUp[0], -viewUp[1], -viewUp[2]];
      rowCosines = viewRight;
      if (!rowCosines || !columnCosines || rotation === undefined) {
        return;
      }

      const markers = _getOrientationMarkers(
        rowCosines,
        columnCosines,
        rotation,
        flipVertical,
        flipHorizontal
      );

    orientationMarkers.map((m) => {
      //console.log(`markers are: ${ markers[m]}`);
      customorientation.addOrientationMarker(viewport, m, markers[m]);
    });
}
function getViewportElement(element,index, orientationMarkers = ['top', 'left','right','bottom']) {
  let rowCosines, columnCosines;
const customorientation = new CustomOrientationMarkerTool();
  //viewport = getEnabledElement(elements[0])?.viewport;

  //const { viewUp, viewPlaneNormal } = viewport.getCamera();
 // elements.forEach((element, index) => {
    element.addEventListener(Enums.Events.CAMERA_MODIFIED, cameraModifiedListener);

    const renderingEngine = getRenderingEngine(renderingEngineId);

    // Get the stack viewport
    const viewport = <Types.IVolumeViewport>(
      renderingEngine.getViewport(viewportIds[index])
    );
    const { viewUp, viewPlaneNormal } = viewport.getCamera();
  rotation = parseFloat(dataElement.getAttribute('rotation'));
  flipVertical = parseFloat(dataElement.getAttribute("flipVertical"));
  flipHorizontal = parseFloat(dataElement.getAttribute("flipHorizontal"));

      const viewRight = vec3.create();
      vec3.cross(viewRight, viewUp, viewPlaneNormal);

      columnCosines = [-viewUp[0], -viewUp[1], -viewUp[2]];
      rowCosines = viewRight;
      if (!rowCosines || !columnCosines || rotation === undefined) {
        return;
      }

      const markers = _getOrientationMarkers(
        rowCosines,
        columnCosines,
        rotation,
        flipVertical,
        flipHorizontal
      );

    orientationMarkers.map((m) => {
     // console.log(`markers are: ${ markers[m]}`);
      customorientation.addOrientationMarker(viewport, m, markers[m]);
    });

  //});

}
const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);
/**
 * Runs the demo
 */
async function run() {
  // Init Cornerstone and related libraries
  await initDemo();
  await csInit();
  await csTools3dInit();

  // Add tools to Cornerstone3D
  cornerstoneTools.addTool(WindowLevelTool);
  cornerstoneTools.addTool(PanTool);
  cornerstoneTools.addTool(StackScrollMouseWheelTool);
  cornerstoneTools.addTool(LengthTool);
  cornerstoneTools.addTool(HeightTool);
  cornerstoneTools.addTool(ZoomTool);
  cornerstoneTools.addTool(ProbeTool);
  cornerstoneTools.addTool(RectangleROITool);
  cornerstoneTools.addTool(EllipticalROITool);
  cornerstoneTools.addTool(CircleROITool);
  cornerstoneTools.addTool(BidirectionalTool);
  cornerstoneTools.addTool(AngleTool);
  cornerstoneTools.addTool(CobbAngleTool);
  cornerstoneTools.addTool(ArrowAnnotateTool);
  cornerstoneTools.addTool(CrosshairsTool);





  // Define a tool group, which defines how mouse events map to tool commands for
  // Any viewport using the group

  const toolGroup2 = ToolGroupManager.createToolGroup(toolGroupId2);

  // Add the tools to the tool group and specify which volume they are pointing at
  toolGroup.addTool(WindowLevelTool.toolName);
  toolGroup.addTool(PanTool.toolName);
  toolGroup.addTool(ZoomTool.toolName);
  toolGroup.addTool(RectangleROITool.toolName);
  toolGroup.addTool(EllipticalROITool.toolName);
  toolGroup.addTool(StackScrollMouseWheelTool.toolName);
  toolGroup.addTool(LengthTool.toolName);
  toolGroup.addTool(HeightTool.toolName);
  toolGroup.addTool(ProbeTool.toolName);
  toolGroup.addTool(RectangleROITool.toolName);
  toolGroup.addTool(CircleROITool.toolName);
  toolGroup.addTool(BidirectionalTool.toolName);
  toolGroup.addTool(AngleTool.toolName);
  toolGroup.addTool(CobbAngleTool.toolName);
  toolGroup.addTool(ArrowAnnotateTool.toolName);


 // toolGroup.setToolEnabled(OrientationMarkerTool.toolName);

 assignStyles();

  //toolGroup.addTool(WindowLevelRegionTool.toolName);

  // Set the initial state of the tools, here we set one tool active on left click.
  // This means left click will draw that tool.
  toolGroup.setToolActive(WindowLevelTool.toolName, {
    bindings: [
      {
        mouseButton: MouseBindings.Primary, // Left Click
      },
    ],
  });







  toolGroup.setToolActive(PanTool.toolName, {
    bindings: [
      {
        mouseButton: MouseBindings.Auxiliary, // Right Click
      },
    ],
  });


  // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
  // hook instead of mouse buttons, it does not need to assign any mouse button.

  toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);

  toolGroup.setToolPassive(ProbeTool.toolName);
  toolGroup.setToolPassive(RectangleROITool.toolName);
  toolGroup.setToolPassive(EllipticalROITool.toolName);
  toolGroup.setToolPassive(CircleROITool.toolName);
  toolGroup.setToolPassive(BidirectionalTool.toolName);
  toolGroup.setToolPassive(AngleTool.toolName);
  toolGroup.setToolPassive(CobbAngleTool.toolName);
  toolGroup.setToolPassive(ArrowAnnotateTool.toolName);

   volumeLoader.registerVolumeLoader('nifti', cornerstoneNiftiImageVolumeLoader);


  // Instantiate a rendering engine

  const renderingEngine = new RenderingEngine(renderingEngineId);

  // Create a stack viewport





  renderingEngine.setViewports(viewportInputArray);

  // Set the tool group on the viewport
  viewportIds.forEach((viewportId) => {
    toolGroup.addViewport(viewportId, renderingEngineId);

  }
  );

  // Define a unique id for the volume
 // const volumeName = 'CT_VOLUME_ID'; // Id of the volume less loader prefix
 // const volumeLoaderScheme = 'cornerstoneStreamingImageVolume'; // Loader id which defines which volume loader to use
  //const volumeId = `${volumeLoaderScheme}:${volumeName}`; // VolumeId with loader id + volume id

  // Define a volume in memory
   await volumeLoader.createAndCacheVolume(volumeId);


 // volume.load();

  const volumeViewportsIds = [];

  for (let i = 0; i < viewportInputArray.length; i++) {
    if(i > 0) break;
    const viewportInput = viewportInputArray[i];
    const { viewportId, type } = viewportInput;

    if (type === ViewportType.STACK) {
      const viewport = <Types.IStackViewport>(
        renderingEngine.getViewport(viewportId)
      );
      viewport.setStack(imageIds);
      viewport.render();
    } else if (type === ViewportType.ORTHOGRAPHIC) {
      volumeViewportsIds.push(viewportId);
    }
  }
 // renderingEngine.disableElement(viewportIds[1]);
 // renderingEngine.disableElement(viewportIds[2])

  if (volumeViewportsIds.length) {

    setVolumesForViewports(renderingEngine, [{ volumeId,  callback: setCtTransferFunctionForVolumeActor, }], volumeViewportsIds);
  }
  setUpSynchronizers();
  // Render the image
  renderingEngine.renderViewports(viewportIds);

  // Set the first viewport as active
  setActiveElement(elements[0]);
  const clickViewport = getEnabledElement(elements[0])?.viewport;
  viewport = clickViewport;
  getViewportElement(elements[0], 0);
  const cornerstoneHeight = ((document.getElementById("viewportGrid").children as HTMLCollection)[currentIndex] as HTMLElement).style.height;

  const chevronLeft = document.createElement('img');
chevronLeft.src = `${window.location.origin}/images/assets/images/chevron-left.svg`;
chevronLeft.id = 'chevronleft';
chevronLeft.className = "chevron";
chevronLeft.style.height = `${innerWidth < 768 ? innerWidth * 0.12 : innerWidth * 0.05}px`;
chevronLeft.style.position = 'absolute';
chevronLeft.style.top = `${(parseInt(cornerstoneHeight.replace("px", ""))) / 8}%`;
chevronLeft.style.left = `${innerWidth < 768 ? 10 :  50 }px`;
content.insertBefore(chevronLeft, content.firstChild);

const chevronRight = document.createElement('img');
chevronRight.src =  `${window.location.origin}/images/assets/images/chevron-right.svg`;
chevronRight.id = 'chevronright';
chevronRight.className = "chevron";
chevronRight.style.height = `${innerWidth < 768 ? innerWidth * 0.15 : innerWidth * 0.05}px`;
chevronRight.style.position = 'absolute';
chevronRight.style.top = `${(parseInt(cornerstoneHeight.replace("px", ""))) / 9}%`;
chevronRight.style.left = `${innerWidth < 768 ? innerWidth * 0.8 + 20 : innerWidth * 0.8 + 120 }px`;
content.appendChild(chevronRight);
document.getElementById("chevronleft").style.display = "none";
//const contents = document.getElementById('contents');


document.getElementById("chevronright").addEventListener("click", () => {
  if (currentIndex < elements.length - 1) {
    csToolsUtilities.cine.stopClip(elements[currentIndex]);

    animateCurl('next');
  }
//  console.log(currentPage);

sharedData();



});
document.getElementById("chevronleft").addEventListener("click", () => {
if(currentIndex > 0) {
  csToolsUtilities.cine.stopClip(elements[currentIndex]);
  animateCurl('prev');
}

//sharedData();
setTimeout(() => {
  console.log(currentIndex);

  if (currentIndex === 0) {
    document.getElementById("chevronleft").style.display = 'none';
    document.getElementById("chevronright").style.display = 'block';
  } else if (currentIndex === elements.length - 1) {
    document.getElementById("chevronleft").style.display = 'block';
    document.getElementById("chevronright").style.display = 'none';
  } else {
    document.getElementById("chevronleft").style.display = 'block';
    document.getElementById("chevronright").style.display = 'block';
  }
  ((document.getElementById("viewportGrid").children as HTMLCollection)[currentIndex] as HTMLElement).style.display = "block";
  const viewportInput = viewportInputArray[currentIndex];
  const { viewportId, type } = viewportInput;
  volumeViewportsIds.splice(0,1,viewportId);
  renderingEngine.disableElement(viewportIds[currentIndex + 1]);

  ((document.getElementById("viewportGrid").children as HTMLCollection)[currentIndex + 1] as HTMLElement).style.display = "none";
  renderingEngine.enableElement(viewportInputArray[currentIndex]);


  if (volumeViewportsIds.length) {

    setVolumesForViewports(renderingEngine, [{ volumeId,  callback: setCtTransferFunctionForVolumeActor, }], volumeViewportsIds);
  }
  //setUpSynchronizers();
  // Render the image
  renderingEngine.renderViewports(viewportIds);
  setActiveElement(elements[currentIndex]);
  const viewportlatest = getEnabledElement(elements[currentIndex])?.viewportId;
  toolGroup.addViewport(viewportlatest)
  getViewportElement(elements[currentIndex], currentIndex);

  //csToolsUtilities.cine.stopClip(activeElement);

}, 600);

});

const sharedData = () => {
  setTimeout(() => {
    if (currentIndex === 0) {
      Object.assign(document.getElementById("chevronleft").style, viewportstyles['1x'].chevronleft);
      document.getElementById("chevronright").style.display = 'block';
    } else if (currentIndex === elements.length - 1) {
      Object.assign(document.getElementById("chevronleft").style, viewportstyles['1x'].chevronleft);
      document.getElementById("chevronright").style.display = 'none';
    } else {
       Object.assign(document.getElementById("chevronleft").style, viewportstyles['1x'].chevronleft);
      document.getElementById("chevronright").style.display = 'block';
    }
    let parentDiv = document.getElementById("viewportGrid");
    ((parentDiv.children as HTMLCollection)[currentIndex] || (parentDiv.appendChild(elements[currentIndex]))).style.display = 'block';
    Object.assign(((document.getElementById("viewportGrid").children as HTMLCollection)[currentIndex] as HTMLElement).style, viewportstyles['1x'].cornerstonelement);
    const viewportInput = viewportInputArray[currentIndex];
    const { viewportId, type } = viewportInput;
    volumeViewportsIds.splice(0,1,viewportId);

    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
    toolGroup.addViewport(viewportIds[currentIndex], renderingEngineId);
    toolGroup.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Primary, // Left Click
        },
      ],
    });

   renderingEngine.disableElement(viewportIds[currentIndex - 1]);
    ((document.getElementById("viewportGrid").children as HTMLCollection)[currentIndex -1] as HTMLElement).style.display = "none";
    renderingEngine.enableElement(viewportInputArray[currentIndex]);

    if (volumeViewportsIds.length) {

      setVolumesForViewports(renderingEngine, [{ volumeId,  callback: setCtTransferFunctionForVolumeActor, }], volumeViewportsIds);
    }
    //setUpSynchronizers();
    // Render the image
toolGroup.addViewport(viewportIds[currentIndex]);
    renderingEngine.renderViewports(viewportIds);
    setActiveElement(elements[currentIndex]);
    getViewportElement(elements[currentIndex], currentIndex);



  }, 600);
};
const viewportGridheight = document.getElementById("viewportGrid").offsetHeight;
const viewportOffsettop = document.getElementById("viewportGrid").offsetTop;
const viewportstyles = {
  '1x': {
    viewportGrid: { width: `${innerWidth * 0.8}px`,  marginLeft: `${innerWidth * 0.1}px`, marginRight: `${innerWidth * 0.1}px`,
    borderRadius: '12px',
    border: '1px solid #ccc',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    transform: 'translateZ(50px)',
    background: 'white',
    transition: 'transform 0.5s ease, box-shadow 0.5s ease',
    transformOrigin: 'bottom left',
    backfaceVisibility: 'hidden',
    overflow: 'hidden',
    transformStyle: 'preserve-3d'},

   // viewport: { display: 'block' },
    chevron:  {
      position: 'absolute',
      top:`${(parseInt(cornerstoneHeight.replace("px", ""))) / 9}%`,
      height: `${innerWidth < 768 ? innerWidth * 0.15 : innerWidth * 0.05}px`,
      left: `${innerWidth < 768 ? innerWidth * 0.8 + 20 : innerWidth * 0.8 + 90 }px`,
      display: 'block'

    },
    cornerstonelement: {
      width: `${ innerWidth < 768 ? innerWidth * 0.7 : innerWidth * 0.8}px`, display: 'block'
    },
    chevronleft: {

height: `${innerWidth < 768 ? innerWidth * 0.12 : innerWidth * 0.05}px`,
position: 'absolute',
top:  `${(parseInt(cornerstoneHeight.replace("px", ""))) / 9}%`,
left: `${innerWidth < 768 ? 10 :  20 }px`,
display: 'block'
  },
  },
  '3x': {
   viewportGrid: { width: '100%', marginLeft: '0px', marginRight: '0px', display: 'flex', flexDirection: 'row'},
   // viewport: { display: 'flex' },
    chevron: { display: 'none', height: '0px', left: '0px', top: '0px' },
     cornerstonelement: {
      width: `${ innerWidth / 3}px`, height: `${innerHeight / 3}`, display: 'block', margin: '1px'
    }
  },

};
Object.assign(viewportGrid.style, viewportstyles["1x"].viewportGrid);
document.getElementById("selectelement").addEventListener('change', threeColsolution);
 //testingViewport(elements);

function threeColsolution(e) {


  let selectedValue = (e.target as HTMLSelectElement).value;
  let parentGrid = document.getElementById("viewportGrid");
  if(parentGrid.children.length >= 1) {
   Array.from(parentGrid.children).forEach((ele, index) => {
renderingEngine.disableElement(viewportIds[index]);
  (ele as HTMLElement).style.display = "none";
    });
   // parentGrid.innerHTML = '';
  }
  //
  console.log(parentGrid);
  // Clear existing viewports
  let chevrons = document.querySelectorAll(".chevron");
  if (selectedValue === '1x') {

    if (!parentGrid.hasChildNodes()) {
      parentGrid.appendChild(elements[0]);
    } else {
      Object.assign(((document.getElementById("viewportGrid").children as HTMLCollection)[0] as HTMLElement).style, viewportstyles[ selectedValue ].cornerstonelement);
    }
    Object.assign(document.getElementById("viewportGrid").style, viewportstyles[ selectedValue ].viewportGrid);
    Object.assign(document.getElementById("chevronright").style, viewportstyles[ selectedValue ].chevron);

    const viewportInput = viewportInputArray[0];
    const { viewportId, type } = viewportInput;
    volumeViewportsIds.splice(0, volumeViewportsIds.length);
    volumeViewportsIds.push(viewportId);


    renderingEngine.enableElement(viewportInputArray[0]);

    if (volumeViewportsIds.length) {

      setVolumesForViewports(renderingEngine, [{ volumeId,  callback: setCtTransferFunctionForVolumeActor, }], volumeViewportsIds);
    }
    renderingEngine.renderViewports(viewportIds);
    setActiveElement(elements[0]);
    getViewportElement(elements[0], 0);


  } else if (selectedValue === '3x') {

    Object.assign( document.getElementById("viewportGrid").style, viewportstyles[ selectedValue ].viewportGrid);
    chevrons.forEach((chevron) => Object.assign((chevron as HTMLElement).style, viewportstyles[selectedValue].chevron));

if(parentGrid.children.length === 1) {
 // ((parentGrid.children as HTMLCollection)[0] as HTMLElement).style.display = "none";

  elements.forEach((element,index) => {
    //element.style.border = 'solid 5px rgba(255, 0, 0, 1)';
    if(index > 2) return;
    element.style.width = `${innerWidth /3}px`;
    element.style.height = `${innerHeight /3}px`;
    element.style.margin = '1px';
   Object.assign(((document.getElementById("viewportGrid").children as HTMLCollection)[0] as HTMLElement).style, viewportstyles[selectedValue].cornerstonelement);

    document.getElementById("viewportGrid").appendChild(element);


  });

} else {
  Array.from(parentGrid.children).forEach((ele,index) => {
  (ele as HTMLElement).style.display = "block";
  });
}

    renderingEngine.setViewports(viewportInputArray);
  volumeViewportsIds.splice(0, volumeViewportsIds.length);
    // Set the tool group on the viewport
    viewportIds.forEach((viewportId) => {
      toolGroup.addViewport(viewportId, renderingEngineId);
    }
  );
      for (let i = 0; i < viewportInputArray.length; i++) {

        const viewportInput = viewportInputArray[i];
        const { viewportId, type } = viewportInput;

        if (type === ViewportType.STACK) {
          const viewport = <Types.IStackViewport>(
            renderingEngine.getViewport(viewportId)
          );
          viewport.setStack(imageIds);
          viewport.render();
        } else if (type === ViewportType.ORTHOGRAPHIC) {
          volumeViewportsIds.push(viewportId);
        }
      }
      if (volumeViewportsIds.length) {

        setVolumesForViewports(renderingEngine, [{ volumeId,  callback: setCtTransferFunctionForVolumeActor, }], volumeViewportsIds);
      }
      //setUpSynchronizers();
      // Render the image
      renderingEngine.renderViewports(viewportIds);
       elements.forEach((ele, index) => {
        getViewportElement(elements[index], index);
       })

  }

}
}

run();
