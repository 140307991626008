import vtkOrientationMarkerWidget from '@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget';
import vtkAnnotatedCubeActor from '@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor';


class  CustomOrientationMarkerTool {
  async addOrientationMarker(viewport, position, text) {
    const actor = vtkAnnotatedCubeActor.newInstance();
    switch (position) {
      case 'top':
        actor.setXPlusFaceProperty({text, faceRotation: 90}); // or actor.setXPlusFaceProperty(null)
        actor.setXMinusFaceProperty({text});
        actor.setYPlusFaceProperty({text});
        actor.setYMinusFaceProperty({ text });
        actor.setZMinusFaceProperty({text, faceRotation: 180});
        actor.setZPlusFaceProperty({text  });
        break;
      case 'left':
        actor.setXPlusFaceProperty({text, faceRotation: 90});
        actor.setXMinusFaceProperty({ text });
        actor.setYPlusFaceProperty({text});
        actor.setYMinusFaceProperty({text});
        actor.setZMinusFaceProperty({text, faceRotation: 180});
        actor.setZPlusFaceProperty({text});
        break;
      case 'right':
        actor.setXPlusFaceProperty({ text, faceRotation: 90 });
        actor.setXMinusFaceProperty({text});
        actor.setYPlusFaceProperty({text});
        actor.setYMinusFaceProperty({text});
        actor.setZMinusFaceProperty({text, faceRotation: 180});
        actor.setZPlusFaceProperty({text});
        break;
      case 'bottom':
        actor.setXPlusFaceProperty({text, faceRotation: 90});
        actor.setXMinusFaceProperty({text});
        actor.setYPlusFaceProperty({ text });
        actor.setYMinusFaceProperty({text});
        actor.setZMinusFaceProperty({text, faceRotation: 180});
        actor.setZPlusFaceProperty({text});
        break;
    };
    const renderer = viewport.getRenderer();
    const renderWindow = viewport.getRenderingEngine().offscreenMultiRenderWindow.getRenderWindow();
    const orientationWidget = vtkOrientationMarkerWidget.newInstance({
      actor,
      interactor: renderWindow.getInteractor(),
      parentRenderer: renderer,
    });

    orientationWidget.setEnabled(true);
    orientationWidget.setViewportCorner(this.getCorner(position));
    orientationWidget.setViewportSize(0.05);
    orientationWidget.setMinPixelSize(50);
    orientationWidget.setMaxPixelSize(100);

    viewport.addWidget(`OrientationMarker-${position}`, orientationWidget);
    renderWindow.render();
    viewport.getRenderingEngine().render();
  }

  getCorner(position) {
    switch (position) {
      case 'top':
        return vtkOrientationMarkerWidget.Corners.TOP;
      case 'left':
        return vtkOrientationMarkerWidget.Corners.CENTER_LEFT;
      case 'right':
        return vtkOrientationMarkerWidget.Corners.CENTER_RIGHT;
      case 'bottom':
        return vtkOrientationMarkerWidget.Corners.BOTTOM;
      default:
        return vtkOrientationMarkerWidget.Corners.TOP_LEFT;
    }
  }
}
export default  CustomOrientationMarkerTool;
